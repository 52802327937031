
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.category-list {
    position: relative;

    .title {
        line-height: normal;
        @include handleAllBreakpoints(
            "font-size",
            "titleSize",
            "24px::var(--global-sections-typography-title-size)"
        );
        @include handleAllBreakpoints("color", "titleColor", "#000");
        @include handleAllBreakpoints(
            "font-weight",
            "titleWeight",
            "var(--global-sections-typography-title-weight)"
        );
        @include handleAllBreakpoints(
            "text-align",
            "titleAlign",
            "var(--global-sections-typography-title-align)"
        );
        @include handleAllBreakpoints("margin", "titleMargin", "0 0 1.5rem 0");
    }

    .description {
        line-height: normal;
        @include handleAllBreakpoints(
            "font-size",
            "descriptionSize",
            "16px::var(--global-sections-typography-description-size)"
        );
        @include handleAllBreakpoints("color", "descriptionColor", "#000");
        @include handleAllBreakpoints(
            "font-weight",
            "descriptionWeight",
            "var(--global-sections-typography-description-weight)"
        );
        @include handleAllBreakpoints(
            "text-align",
            "descriptionAlign",
            "var(--global-sections-typography-description-align)"
        );
        @include handleAllBreakpoints(
            "margin",
            "descriptionMargin",
            "0 0 1.5rem 0"
        );
    }

    .categories {
        display: flex;
        flex-wrap: wrap;
        @include handleAllBreakpoints("gap", "categoriesGap", "15px::30px");
        @include handleAllBreakpoints(
            "justify-content",
            "categoriesAlign",
            "center"
        );
    }

    .category {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        position: relative;

        @include handleAllBreakpoints(
            "background",
            "categoryBackgroundColor",
            "#fff"
        );
        @include handleAllBreakpoints("box-shadow", "categoryShadow");
        @include handleAllBreakpoints(
            "border-radius",
            "categoryBorderRadius",
            "5px"
        );
        @include handleAllBreakpoints(
            "border",
            "categoryBorder",
            "1px solid var(--primary)"
        );
        @include handleAllBreakpoints("padding", "categoryPadding", "1rem");
        @include handleAllBreakpoints("width", "categoryWidth", "250px");
        @include handleAllBreakpoints("height", "categoryHeight", "auto");
        @include handleAllBreakpoints("align-items", "categoryAlign", "center");
        @include handleAllBreakpoints(
            "justify-content",
            "categoryVerticalAlign",
            "center"
        );

        h3 {
            line-height: normal;
            @include handleAllBreakpoints(
                "font-size",
                "categoryTitleSize",
                "16px"
            );
            @include handleAllBreakpoints(
                "color",
                "categoryTitleColor",
                "#000"
            );
            @include handleAllBreakpoints("font-weight", "categoryTitleWeight");
            @include handleAllBreakpoints(
                "text-align",
                "categoryAlign",
                "var(--global-sections-typography-title-align)"
            );
            @include handleAllBreakpoints("margin", "categoryTitleMargin");
        }
        p {
            line-height: normal;
            @include handleAllBreakpoints(
                "font-size",
                "categoryDescriptionSize",
                "14px"
            );
            @include handleAllBreakpoints(
                "color",
                "categoryDescriptionColor",
                "var(--global-sections-typography-description-color)"
            );
            @include handleAllBreakpoints(
                "font-weight",
                "categoryDescriptionWeight",
                "300"
            );
            @include handleAllBreakpoints(
                "text-align",
                "categoryAlign",
                "center"
            );
            @include handleAllBreakpoints(
                "margin",
                "categoryDescriptionMargin"
            );
        }

        &:hover {
            @include handleAllBreakpoints(
                "background",
                "categoryHoverBackgroundColor",
                "#fff"
            );
            @include handleAllBreakpoints("color", "categoryHoverTextColor");
            @include handleAllBreakpoints("box-shadow", "categoryHoverShadow");
            @include handleAllBreakpoints("border", "categoryHoverBorder");

            .imageContainer {
                @include handleAllBreakpoints(
                    "background",
                    "categoryImageHoverBackgroundColor",
                    "unset"
                );
                img {
                    @include handleAllBreakpoints(
                        "filter",
                        "categoryImageHoverFilter"
                    );
                }
            }
        }

        .imageContainer {
            @include handleAllBreakpoints(
                "justify-content",
                "categoryAlign",
                "var(--global-sections-typography-title-align)"
            );
            @include handleAllBreakpoints("margin", "imageMargin");
            @include handleAllBreakpoints(
                "width",
                "categoryImageWidth",
                "80px"
            );
            @include handleAllBreakpoints(
                "height",
                "categoryImageHeight",
                "80px"
            );
            @include handleAllBreakpoints(
                "filter",
                "categoryImageFilter",
                "unset"
            );
            @include handleAllBreakpoints(
                "padding",
                "categoryImagePadding",
                "0"
            );
            @include handleAllBreakpoints(
                "border",
                "categoryImageBorder",
                "unset"
            );
            @include handleAllBreakpoints(
                "border-radius",
                "categoryImageBorderRadius",
                "0"
            );
            @include handleAllBreakpoints(
                "background",
                "categoryImageBackgroundColor",
                "unset"
            );
        }

        a {
            font-weight: 500;
            transition: all 0.2s ease 0s, transform 0.3s ease 0s;

            @include handleAllBreakpoints(
                "color",
                "categoryButtonTextColor",
                "var(--global-sections-button-text-color, #fff)"
            );
            @include handleAllBreakpoints(
                "background-color",
                "categoryButtonBackgroundColor",
                "var(--global-sections-button-background-color, var(--primary))"
            );
            @include handleAllBreakpoints(
                "margin",
                "categoryButtonMargin",
                "auto 0 0 0"
            );
            @include handleAllBreakpoints(
                "width",
                "categoryButtonWidth",
                "fit-content"
            );
            @include handleAllBreakpoints(
                "box-shadow",
                "categoryButtonShadow",
                "unset"
            );
            @include handleAllBreakpoints(
                "padding",
                "categoryButtonPadding",
                "10px 37px"
            );
            @include handleAllBreakpoints(
                "border-radius",
                "categoryButtonBorderRadius",
                "5px"
            );
            @include handleAllBreakpoints(
                "border",
                "categoryButtonBorder",
                "unset"
            );

            &:hover {
                // transform: translateY(-5px);
                @include handleAllBreakpoints(
                    "box-shadow",
                    "categoryButtonHoverShadow",
                    "var(--primary) 0px 10px 20px -10px"
                );
                @include handleAllBreakpoints(
                    "background-color",
                    "categoryButtonHoverBackgroundColor",
                    "var(--primary-darken-1)"
                );
                @include handleAllBreakpoints(
                    "color",
                    "categoryButtonHoverTextColor",
                    "var(--global-sections-button-text-color, #fff)"
                );
            }

            &.without-button {
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                overflow: hidden;
                padding: 0;
            }
        }
    }

    .search {
        @include handleAllBreakpoints(
            "background",
            "searchBackgroundColor",
            "#fff"
        );
        @include handleAllBreakpoints("box-shadow", "searchShadow");
        @include handleAllBreakpoints(
            "border",
            "searchBorder",
            "1px solid var(--primary)"
        );
        @include handleAllBreakpoints("width", "searchWidth", "100%::45%");
        @include handleAllBreakpoints("height", "searchHeight", "50px");
        @include handleAllBreakpoints("padding", "searchPadding", "0 1rem");
        @include handleAllBreakpoints(
            "border-radius",
            "searchBorderRadius",
            "5px"
        );
        @include handleAllBreakpoints("margin", "searchMargin", "2rem auto");
    }

    .no-search-results-text {
        text-align: center;
        margin: 5rem 0;
        @include handleAllBreakpoints(
            "font-size",
            "noSearchResultTextSize",
            "16px::24px"
        );
        @include handleAllBreakpoints(
            "color",
            "noSearchResultTextColor",
            "#fff"
        );
        @include handleAllBreakpoints(
            "font-weight",
            "noSearchResultTextWeight",
            "600"
        );
    }

    .categoryGroupsContainer {
        display: flex;
        justify-content: center;

        @include handleAllBreakpoints(
            "margin",
            "categoryGroupsMargin",
            "0 0 28px 0"
        );
        @include handleAllBreakpoints("gap", "categoryGroupsGap", "10px::30px");

        .categoryGroup {
            @include handleAllBreakpoints("color", "groupColor", "#fff");
            @include handleAllBreakpoints(
                "border-radius",
                "groupActiveBorderRadius",
                "50px"
            );
            @include handleAllBreakpoints("font-size", "groupSize", "16px");
            @include handleAllBreakpoints("font-weight", "groupWeight", "300");
            @include handleAllBreakpoints(
                "padding",
                "groupPadding",
                "7px 16px::9px 27px"
            );
            line-height: 25px;
            cursor: pointer;

            &:hover {
                @include handleAllBreakpoints(
                    "color",
                    "groupHoverColor",
                    "#06ff60"
                );
            }
            &.activeCategoryGroup {
                @include handleAllBreakpoints(
                    "border",
                    "groupActiveBorder",
                    "1px solid #06ff60"
                );
                @include handleAllBreakpoints(
                    "color",
                    "groupActiveColor",
                    "#06ff60"
                );
            }
        }
    }
}
